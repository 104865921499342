import React, { useEffect, useRef } from 'react';
import { useGlobalEvents } from './global-events';
import {
  EventBrokerCallback,
  EventBrokerCallbackRecord,
  EventBrokerContext,
} from './event-broker-context';
import {
  evaluateEventCondition,
  GLOBAL_EVENT_TYPES,
  ProjectInteraction,
  VevDispatchEvent,
  VevTriggerType,
  dispatchExternalTrackingEvent,
  isTriggerGlobal,
  isTargetVariable,
  triggerShareDialog,
} from '@vev/interactions';
import { useGlobalStore } from '../../core/';
import { useGlobalTimer } from './global-timer';
import { VariableManager } from '@vev/variables';

const VARIANT_EVENTS: string[] = [
  GLOBAL_EVENT_TYPES.CHANGE_VARIANT,
  GLOBAL_EVENT_TYPES.TOGGLE_VARIANT,
];
interface Props {
  children: React.ReactElement;
}

// █▀▄▀█ █▀▀ █▀ █▀ ▄▀█ █▀▀ █▀▀   █▄▄ █▀█ █▀█ █▄▀ █▀▀ █▀█
// █░▀░█ ██▄ ▄█ ▄█ █▀█ █▄█ ██▄   █▄█ █▀▄ █▄█ █░█ ██▄ █▀▄
//
//                     ▄░▀▀▀▀░▄
//              ██    █░██▀██░█▄    Jasså?
//               █      ▀▄ ▀ ▄▀
//               █▄▄▄▄   ████

export function EventBrokerProvider({ children }: Props) {
  useGlobalEvents();
  const { registerForTimeslot, clearTimeslots } = useGlobalTimer();

  const eventCallbacks = useRef<EventBrokerCallbackRecord>({});

  const [projectInteractions, projectKey = '', pageKey = ''] = useGlobalStore((store) => {
    const { interactions, project, route } = store;
    return [interactions, project, route.pageKey];
  });

  useEffect(() => {
    function handleEvent(event: CustomEvent<VevDispatchEvent>) {
      const interactions: ProjectInteraction[] = [];

      // Main component logic - Support for instances
      if (event.detail.instanceKeyChains) {
        for (const instanceKeyChain of event.detail.instanceKeyChains) {
          const contentKeyChain = `${event.detail.contentKey}${instanceKeyChain || ''}`;
          interactions.push(...(projectInteractions.trigger?.widget?.[contentKeyChain] || []));
        }
      }

      // If the event has a contentKey, add all interactions for that contentKey
      if (event.detail.contentKey) {
        interactions.push(
          ...(projectInteractions.trigger?.widget?.[event.detail.contentKey] || []),
        );
      }

      if (event.detail.componentKey) {
        interactions.push(
          ...(projectInteractions.trigger?.widget?.[event.detail.componentKey] || []),
        );
      }

      // If the event is a global trigger, add interactions for that global trigger type
      if (event.detail.type && isTriggerGlobal(event.detail.type)) {
        interactions.push(...(projectInteractions.trigger?.global?.[event.detail.type] || []));
      }

      // Loop through matches and fire callback if condition is met
      interactions
        .filter((i) => event.detail.type === i.trigger?.type)
        .forEach((interaction) => {
          const { instanceKeyChains } = event.detail;
          const isDisabled = interaction?.disabled;
          const contentKey = interaction?.event?.contentKey;

          const callBackname = `${interaction?.event?.type}.` + contentKey;
          const callbacks = [];

          if (eventCallbacks.current[callBackname])
            callbacks.push(eventCallbacks.current[callBackname]);

          // Check if the event is meant for a main component

          if (instanceKeyChains) {
            for (const chain of instanceKeyChains) {
              // Check if a callback exists with the keyChain provided
              const chainCallback = eventCallbacks.current[callBackname + chain];
              if (chainCallback) {
                callbacks.push(chainCallback);
              }
            }
          }

          if (callbacks.length && !isDisabled) {
            for (const callback of callbacks) {
              const args = interaction.event?.args || event.detail.args || {};
              if (interaction?.trigger?.condition) {
                const success = evaluateEventCondition(
                  interaction?.trigger?.condition,
                  event.detail.args,
                );
                if (success) callback({ ...args, interactionKey: interaction.key });
              } else {
                callback({ ...args, interactionKey: interaction.key });
              }
            }
          }

          // Handle tracking events
          if (interaction.event?.type === GLOBAL_EVENT_TYPES.TRACK && !isDisabled) {
            // Dispatch custom event for tracking
            dispatchExternalTrackingEvent(interaction.event?.args, {
              projectKey,
              pageKey,
              contentKey: interaction.trigger?.contentKey,
            });
          }

          // Handle share events
          if (interaction.event?.type === GLOBAL_EVENT_TYPES.SHARE && !isDisabled) {
            triggerShareDialog(interaction.event?.args);
          }
        });
    }

    window.addEventListener('@@vev', handleEvent as EventListener);

    return () => {
      window.removeEventListener('@@vev', handleEvent as EventListener);
    };
  }, [projectInteractions, projectKey, pageKey]);

  // Register timer callbacks
  useEffect(() => {
    if (projectInteractions) {
      // Find all project interactions with a timer trigger
      const timerInteractions = projectInteractions.trigger?.global?.onTimer?.filter(
        (interaction) => !interaction.deleted,
      );

      // Register all timer callbacks
      timerInteractions &&
        timerInteractions.forEach((interaction) => {
          // Get the delay from the trigger condition (delay = 100)
          const delayValue = interaction.trigger?.condition?.split('=')[1];
          const delay = delayValue ? parseInt(delayValue) : 1000;
          const runOnce = interaction.trigger?.condition?.includes('timeout');

          registerForTimeslot(
            interaction.key,
            delay,
            () => {
              const callbackName = `${interaction?.event?.type}.${interaction?.event?.contentKey}`;
              const callback = eventCallbacks.current[callbackName];
              const isDisabled = interaction?.disabled;

              if (callback && !isDisabled) {
                const args = interaction.event?.args || {};
                callback({ ...args, interactionKey: interaction.key });
              }
            },
            !!runOnce,
          );
        });

      return clearTimeslots;
    }
  }, [projectInteractions]);

  // Find all project interactions with a variable event
  useEffect(() => {
    if (projectInteractions) {
      Object.keys(projectInteractions.event.widget).forEach((key) => {
        const widgetInteractions = projectInteractions.event.widget[key];
        widgetInteractions.forEach((interaction) => {
          if (isTargetVariable(interaction?.event?.type)) {
            if (interaction.event?.type && interaction.event.contentKey) {
              const callbackName = `${interaction.event.type}.${interaction.event.contentKey}`;
              if (interaction?.event?.type === 'SET_VARIABLE') {
                eventCallbacks.current[callbackName] = (args) => {
                  const actualArgs = args || interaction.event?.args;
                  if (interaction.event?.contentKey) {
                    VariableManager.setVariable(interaction.event.contentKey, {
                      value: actualArgs?.value,
                    });
                  }
                };
              }
              if (interaction?.event?.type === 'RESET_VARIABLE') {
                eventCallbacks.current[callbackName] = () => {
                  if (interaction.event?.contentKey) {
                    VariableManager.resetVariable(interaction.event.contentKey);
                  }
                };
              }
            }
          }
        });
      });
    }
  }, [projectInteractions]);

  function addCallback(
    event: VevTriggerType,
    contentKey: string | undefined,
    callback: EventBrokerCallback,
  ) {
    if (event && contentKey) {
      eventCallbacks.current[`${event}.${contentKey}`] = callback;
    }
  }

  function removeCallback(event: VevTriggerType, contentKey: string | undefined) {
    if (event && contentKey) {
      delete eventCallbacks.current[`${event}.${contentKey}`];
    }
  }

  return (
    <EventBrokerContext.Provider value={{ addCallback, removeCallback }}>
      {children}
    </EventBrokerContext.Provider>
  );
}
